import React from 'react';
import Layout from '@components/domain/shared/layout';
import EmptySpace from '@components/ui/empty-space';
import Spacing from '@components/ui/spacing';
import Title from '@components/ui/title';
import SEO from '@components/ui/seo';
import styles from '@styles/pages/terms-and-conditions.module.scss';

const paragraphs = [
  "Ao acessar ao site After School, concorda em cumprir estes termos de serviço, todas as leis e regulamentos aplicáveis ​​e concorda que é responsável pelo cumprimento de todas as leis locais aplicáveis. Se você não concordar com algum desses termos, está proibido de usar ou acessar este site. Os materiais contidos neste site são protegidos pelas leis de direitos autorais e marcas comerciais aplicáveis.",
  "É concedida permissão para baixar temporariamente uma cópia dos materiais (informações ou software) no site After School , apenas para visualização transitória pessoal e não comercial. Esta é a concessão de uma licença, não uma transferência de título e, sob esta licença, você não pode:",
  "1) modificar ou copiar os materiais;",
  "2) usar os materiais para qualquer finalidade comercial ou para exibição pública (comercial ou não comercial);",
  "3) tentar descompilar ou fazer engenharia reversa de qualquer software contido no site After School;",
  "4) remover quaisquer direitos autorais ou outras notações de propriedade dos materiais; ou",
  "5) transferir os materiais para outra pessoa ou 'espelhe' os materiais em qualquer outro servidor.",
  "Esta licença será automaticamente rescindida se você violar alguma dessas restrições e poderá ser rescindida por After School a qualquer momento. Ao encerrar a visualização desses materiais ou após o término desta licença, você deve apagar todos os materiais baixados em sua posse, seja em formato eletrónico ou impresso.",
  "Os materiais no site da After School são fornecidos 'como estão'. After School não oferece garantias, expressas ou implícitas, e, por este meio, isenta e nega todas as outras garantias, incluindo, sem limitação, garantias implícitas ou condições de comercialização, adequação a um fim específico ou não violação de propriedade intelectual ou outra violação de direitos.",
  "Além disso, o After School não garante ou faz qualquer representação relativa à precisão, aos resultados prováveis ​​ou à confiabilidade do uso dos materiais em seu site ou de outra forma relacionado a esses materiais ou em sites vinculados a este site.",
  "Em nenhum caso o After School ou seus fornecedores serão responsáveis ​​por quaisquer danos (incluindo, sem limitação, danos por perda de dados ou lucro ou devido a interrupção dos negócios) decorrentes do uso ou da incapacidade de usar os materiais em After School, mesmo que After School ou um representante autorizado da After School tenha sido notificado oralmente ou por escrito da possibilidade de tais danos. Como algumas jurisdições não permitem limitações em garantias implícitas, ou limitações de responsabilidade por danos conseqüentes ou incidentais, essas limitações podem não se aplicar a você.",
  "Os materiais exibidos no site da After School podem incluir erros técnicos, tipográficos ou fotográficos. After School não garante que qualquer material em seu site seja preciso, completo ou atual. After School pode fazer alterações nos materiais contidos em seu site a qualquer momento, sem aviso prévio. No entanto, After School não se compromete a atualizar os materiais.",
  "O After School não analisou todos os sites vinculados ao seu site e não é responsável pelo conteúdo de nenhum site vinculado. A inclusão de qualquer link não implica endosso por After School do site. O uso de qualquer site vinculado é por conta e risco do usuário.",
  "O After School pode revisar estes termos de serviço do site a qualquer momento, sem aviso prévio. Ao usar este site, você concorda em ficar vinculado à versão atual desses termos de serviço.",
  "Estes termos e condições são regidos e interpretados de acordo com as leis do After School e você se submete irrevogavelmente à jurisdição exclusiva dos tribunais naquele estado ou localidade."
];

const TermsAndConditions = () => (
  <Layout>
    <SEO
      title="Termos e condições"
      description="afterschool. Cursos extracurriculares de excelência em ciência, tecnologia, engenharia e matemática."
    />
    <EmptySpace desktop={{ margin: 120 }} mobile={{ margin: 100 }} />
    <Spacing>
      <div className={styles.title}>
        <Title value="Termos e condições" />
      </div>
      <div className={styles.content}>
        {paragraphs.map((text, index) => (<p key={index}>{text}</p>))}
      </div>
      <EmptySpace />
    </Spacing>
  </Layout>
);

export default TermsAndConditions;
